function sum( obj ) {
  var sum = 0;
  for( var el in obj ) {
    if( obj.hasOwnProperty( el ) ) {
      sum += parseFloatComma( obj[el] );
    }
  }
  return sum;
}

function parseFloatComma(val) {
  if (!val) {
    return 0;
  }

  r = parseFloat(val.toString().replace(',', '.'));
  if (isNaN(r)) {
    r = 0
  }
  return r;
}
