
;( function( $ ) {

    $( '.swipebox' ).swipebox({
    	loopAtEnd: true,
    	beforeOpen: function() {
            $( "html, body" ).addClass( "body-no-scroll" );
        },
        afterClose: function() {
            $( "html, body" ).removeClass( "body-no-scroll" ); 
        },
    });

} )( jQuery );